/**
 * Stripe Utils
 */

export const products = [
  {
    id: process.env.GATSBY_STRIPE_FULL_MEMBER_ID,
    description: "Full Membership",
    price: 70,
  },
  {
    id: process.env.GATSBY_STRIPE_ASSOC_MEMBER_ID,
    description: "Associate Membership",
    price: 30,
  },
  {
    id: process.env.GATSBY_STRIPE_OVERSEAS_MEMBER_ID,
    description: "Overseas Membership",
    price: 30,
  },
]

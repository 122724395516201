import React, { useEffect, useState } from "react"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { useAuth0 } from "@auth0/auth0-react"
import { navigate } from "gatsby"

import Payment from "../../components/account/pages/Payment"
import { AuthProvider } from "../../providers/auth/AuthProvider"
import PageLoader from "../../components/page-loader"

/**
 * Stripe Elements Provider
 * Required to use the Stripe Elements Library
 */
const STRIPE_PUBLIC_KEY = process.env.GATSBY_STRIPE_PUBLIC_KEY
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY)

export default function PaymentPage() {
  const [token, setToken] = useState()
  const [sub, setSub] = useState()
  const { getIdTokenClaims } = useAuth0()

  useEffect(() => {
    function getClaims() {
      getIdTokenClaims()
        .then((res) => {
          if (res && res.__raw) {
            setSub({
              uid: res.sub,
              name: `${res.given_name} ${res.family_name}`,
              email: res.email,
            })

            setToken(res.__raw)
          } else {
            console.log("Unauthorised. Logging out")
            navigate("/")
          }
        })
        .catch((err) => {
          alert(
            "There was a problem loading this page. Please try again or contact support if the problem persists."
          )
          console.error(
            "Unable to validate session, error with checkSession()",
            err
          )
          navigate("/")
        })
    }

    // If there is no token then retrieve it
    if (!token) {
      getClaims()
    }
  }, [])

  return (
    <Elements stripe={stripePromise}>
      <AuthProvider>
        {token ? <Payment token={token} sub={sub} /> : <PageLoader />}
      </AuthProvider>
    </Elements>
  )
}

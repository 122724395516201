async function createStripeSession({ customer_id, plan_id }, token) {
  const response = await fetch("/.netlify/functions/create-checkout-session", {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ customer_id, plan_id }),
  })

  return await response.json()
}

export default createStripeSession
